<!--门票销售数据-->
<template>
    <div class="management-box">
        <div class="tip-box flex ac jb">
            <div class="flex ac">
                <div class="every-tip-actived" @click="handleJump(0)">日游数据</div>
                <div class="every-tip" @click="handleJump(1)">夜游数据</div>
            </div>
            <div class="all-db-box flex ac jb">
                <div class="all-db flex ac">
                    <div class="every-db" v-for="(item,index) in dbListAll" :key="index"
                         @click="handleEdit(2,item,index)">
                        <div class="pp1">{{item.title}}</div>
                        <!--                        <div class="pp2">{{item.num}}</div>-->
                    </div>
                </div>
                <div class="db-box" @click="showDb()">添加对比</div>
            </div>
        </div>
        <div class="all-box flex ac jb">
            <div class="left-box">
                <img class="img1" src="../assets/daymanage/10-1title-jiedai@2x.png" alt="11">
                <div class="search-box flex ac jb">
                    <img src="../assets/daymanage/10-1title-jiedai-1@2x.png" alt="10-1title-jiedai-1@2x">
                    <div class="right-select2 all-date-box flex ac jb">
                        <el-select v-model="Jqvalue" placeholder="景区选择" size="mini"
                                   clearable @change="changeJq2">
                            <el-option
                                    v-for="item in Jqoptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                        <!--                            <div class="all-date-box" style="margin-left: 10px">-->
                        <!--                                <el-date-picker-->
                        <!--                                        v-model="date4"-->
                        <!--                                        type="daterange"-->
                        <!--                                        :clearable="false"-->
                        <!--                                        range-separator="-"-->
                        <!--                                        start-placeholder="开始日期"-->
                        <!--                                        end-placeholder="结束日期"-->
                        <!--                                        :picker-options="pickerOptions"-->
                        <!--                                        value-format="yyyy-MM-dd"-->
                        <!--                                        placement="bottom-start"-->
                        <!--                                        size="small"-->
                        <!--                                        style="width: 100%"-->
                        <!--                                        @change="handleDate4"-->
                        <!--                                ></el-date-picker>-->
                        <!--                            </div>-->
                    </div>
                    <!--                    <div class="db-box" @click="showDb(1)">添加对比</div>-->
                </div>
                <!--                <div class="all-db flex ac">-->
                <!--                    <div class="every-db" v-for="(item,index) in dbList1" :key="index" style="width: 20%"-->
                <!--                         @click="handleEdit(1,item,index)">-->
                <!--                        <div class="pp1">{{item.title}}</div>-->
                <!--                        <div class="pp2">{{item.num}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="mb45">
                    <Echart :options="option1" id="chart1" height="290px" width="520px"></Echart>
                </div>

                <div class="search-box flex ac jb">
                    <img src="../assets/daymanage/10-1title-jiedai-2@2x.png" alt="10-1title-jiedai-1@2x">
                    <!--                    <div class="db-box" @click="showDb(2)">添加对比</div>-->
                </div>
                <!--                <div class="all-db flex ac">-->
                <!--                    <div class="every-db" v-for="(item,index) in dbList2" :key="index" style="width: 20%"-->
                <!--                         @click="handleEdit(2,item,index)">-->
                <!--                        <div class="pp1">{{item.title}}</div>-->
                <!--                        <div class="pp2">{{item.num}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div>
                    <Echart :options="option2" id="chart2" height="290px" width="520px"></Echart>
                </div>
            </div>
            <div class="right-box">
                <div class="info1">
                    <img class="img1" src="../assets/daymanage/10-1title-jingying@2x.png" alt="11">
                    <div class="search-box flex ac jb">
                        <img src="../assets/daymanage/10-1title-jingying-1@2x.png" alt="10-1title-jiedai-1@2x">
                        <!--                        <div class="db-box" @click="showDb(3)">添加对比</div>-->
                    </div>
                    <!--                    <div class="all-db flex ac">-->
                    <!--                        <div class="every-db" v-for="(item,index) in dbList3" :key="index" style="width: 20%"-->
                    <!--                             @click="handleEdit(3,item,index)">-->
                    <!--                            <div class="pp1">{{item.title}}</div>-->
                    <!--                            <div class="pp2">{{item.num}}</div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="mb45">
                        <Echart :options="option3" id="chart3" height="290px" width="520px"></Echart>
                    </div>

                    <div class="search-box renjun-img">
                        <img  src="../assets/daymanage/10-1title-jingying-2@2x.png" alt="10-1title-jiedai-1@2x">
                        <div class="wenHao">
                            <el-popover
                                    placement="top-start"
                                    title=""
                                    width="240"
                                    trigger="hover"
                            >
                                <div class="gongshi" style="white-space: nowrap;font-size: 12px">人均消费=总收入(门票和项目)/总人数</div>
                                <div slot="reference"><img src="../assets/questionIcon.png" alt="Q" style="width: 100%;height: 100%"></div>
                            </el-popover>
                        </div>
                    </div>
                    <!--                    <div class="all-db flex ac">-->
                    <!--                        <div class="every-db" v-for="(item,index) in dbList4" :key="index" style="width: 20%"-->
                    <!--                             @click="handleEdit(4,item,index)">-->
                    <!--                            <div class="pp1">{{item.title}}</div>-->
                    <!--                            <div class="pp2">{{item.num}}</div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div>
                        <Echart :options="option4" id="chart4" height="290px" width="520px"></Echart>
                    </div>
                </div>
                <div class="info2">
                    <div class="flex ac jb">
                        <img class="img3" src="../assets/img/10-1title-tuandui.png" alt="tt2">
                        <!--                        <div class="all-date-box" style="margin-left: 10px">-->
                        <!--                            <el-date-picker-->
                        <!--                                    v-model="date3"-->
                        <!--                                    type="daterange"-->
                        <!--                                    :clearable="false"-->
                        <!--                                    range-separator="-"-->
                        <!--                                    start-placeholder="开始日期"-->
                        <!--                                    end-placeholder="结束日期"-->
                        <!--                                    :picker-options="pickerOptions"-->
                        <!--                                    value-format="yyyy-MM-dd"-->
                        <!--                                    placement="bottom-start"-->
                        <!--                                    size="small"-->
                        <!--                                    style="width: 100%"-->
                        <!--                                    @change="handleDate3"-->
                        <!--                            ></el-date-picker>-->
                        <!--                        </div>-->
                    </div>


                    <img class="img4" src="../assets/daymanage/10-1title-tuandui-1@2x.png" alt="tt3">
                    <div>
                        <Echart :options="option5" id="chart5" height="146px" width="550px"></Echart>
                    </div>
                    <img class="img5" src="../assets/daymanage/10-1title-tuandui-2@2x.png" alt="">

                    <div class="flex ac jb">
                        <Echart :options="option6" id="chart6" height="140px" width="140px"></Echart>
                        <div class="right-data-show">
                            <div class="info1-box">
                                <div class="echart_r">
                                    <div class="echart_r_box" v-for="(item1, index1) in series6" :key="index1">
                                        <div class="echart_r_box1" :style="show1(index1, '4px')">{{ item1.name }}</div>
                                        <div class="echart_r_box2" :style="show1(index1, '1px')">{{ item1.value }}
                                            <span>({{ item1.percent }}%)</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="info3">
                    <div class="flex ac jb mb10">
                        <img src="../assets/img/10-1title-jingying-3.png" alt="">
                        <div class="right-select2 all-date-box flex ac jb">
                            <el-select v-model="Jqvalue" placeholder="景区选择" size="mini"
                                       clearable @change="changeJq">
                                <el-option
                                        v-for="item in Jqoptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <!--                            <div class="all-date-box" style="margin-left: 10px">-->
                            <!--                                <el-date-picker-->
                            <!--                                        v-model="date4"-->
                            <!--                                        type="daterange"-->
                            <!--                                        :clearable="false"-->
                            <!--                                        range-separator="-"-->
                            <!--                                        start-placeholder="开始日期"-->
                            <!--                                        end-placeholder="结束日期"-->
                            <!--                                        :picker-options="pickerOptions"-->
                            <!--                                        value-format="yyyy-MM-dd"-->
                            <!--                                        placement="bottom-start"-->
                            <!--                                        size="small"-->
                            <!--                                        style="width: 100%"-->
                            <!--                                        @change="handleDate4"-->
                            <!--                                ></el-date-picker>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="flex ac jb">
                        <Echart :options="option7" id="chart7" height="140px" width="140px"></Echart>
                        <div class="right-data-show">
                            <div class="info1-box">
                                <div class="echart_r">
                                    <div class="echart_r_box" v-for="(item1, index1) in series7" :key="index1">
                                        <div class="echart_r_box1" :style="show1(index1, '4px')">{{ item1.name }}</div>
                                        <div class="echart_r_box2" :style="show1(index1, '1px')">{{ item1.value }}
                                            <span>({{ item1.percent }}%)</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
                title="添加对比"
                :visible.sync="dialogVisible"
                width="30%"
                top="30vh"
                :modal-append-to-body="false"
        >
            <div>
                <div class="flex ac">
                    <div style="margin-right: 5px;white-space: nowrap">选择年份:</div>
                    <el-date-picker
                            v-model="date2"
                            type="daterange"
                            :clearable="false"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            placement="bottom-start"
                            size="small"
                            style="width: 100%"
                            @change="handleDate2"
                            clearable
                    ></el-date-picker>
                </div>
                <div class="kjxz">快捷选择</div>
                <div class="chooseYearBox flex ac">
                    <div style="margin-right: 5px">选择年份:</div>
                    <el-date-picker
                            v-model="yearValue2"
                            type="year"
                            placeholder="选择年"
                            @change="chooseYaer2"
                            format="yyyy"
                            value-format="yyyy"
                    >
                    </el-date-picker>
                </div>
                <div class="month-box flex fw" style="margin-top: 5px">
                    <div style="margin-right: 5px">选择月份:</div>
                    <div style="width: 80%">
                        <el-checkbox-group v-model="monthValue2" size="mini" :disabled="checkDisable3"
                                           :max="1"
                                           @change="handleMonth2">
                            <el-checkbox label="01" border>一月</el-checkbox>
                            <el-checkbox label="02" border>二月</el-checkbox>
                            <el-checkbox label="03" border>三月</el-checkbox>
                            <el-checkbox label="04" border>四月</el-checkbox>
                            <el-checkbox label="05" border>五月</el-checkbox>
                            <el-checkbox label="06" border>六月</el-checkbox>
                            <el-checkbox label="07" border>七月</el-checkbox>
                            <el-checkbox label="08" border>八月</el-checkbox>
                            <el-checkbox label="09" border>九月</el-checkbox>
                            <el-checkbox label="10" border>十月</el-checkbox>
                            <el-checkbox label="11" border>十一月</el-checkbox>
                            <el-checkbox label="12" border>十二月</el-checkbox>
                        </el-checkbox-group>
                    </div>

                </div>
                <div class="month-box flex fw">
                    <div style="margin-right: 5px">选择节假日:</div>
                    <div style="width: 80%">
                        <el-checkbox-group v-model="holidayValue2" size="mini" :disabled="checkDisable4"
                                           :max="1"
                                           @change="handleHoliday2">
                            <div class="flex ac fw">
                                <div v-for="item in holidayList" :key="item.id"
                                     style="margin-right: 5px">
                                    <el-checkbox :label="item.name" border/>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="cannelDialog">取 消</el-button>
    <el-button type="warning" @click="moveDialog" v-if="editState">移除对比</el-button>
    <el-button type="primary" @click="sumbitDialog">确认对比</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
    import {formatTime} from "../utils/index.js";
    import Echart from "../common/echart";
    import {
        tjHolidayList,
        tjReportTouristType,
        tjReportTourist,
        tjReportIncome,
        tjReportIncomePer,
        tjReportTeamType,
        tjReportTeamNum,
        tjScenicSpotList,
        tjReportIncomeType
    } from "../api";

    export default {
        components: {
            Echart,
        },
        name: "",
        data() {
            return {
                Jqvalue: 0,
                Jqvalue2: 0,
                Jqoptions: [],
                date1: [],
                date2: [],
                month: formatTime(new Date(), "yyyy-MM"),
                month2: formatTime(new Date(), "yyyy-MM"),
                pickerOptions: {
                    // 限制日期范围为一个月
                    onPick: ({maxDate, minDate}) => {
                        this.isRestart = false;
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = "";
                        }
                    },

                    disabledDate: (time) => {
                        if (this.pickerMinDate !== "") {
                            const one = 12 * 31 * 24 * 3600 * 1000;
                            const minTime = this.pickerMinDate - one;
                            const maxTime = this.pickerMinDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime;
                        }
                    },
                },
                kjvisible1: false,
                kjvisible2: false,
                kjvisible3: false,
                kjvisible4: false,
                yearValue1: new Date().getFullYear().toString(),
                monthValue1: [],
                holidayList: [],
                holidayValue1: [],
                checkDisable: false,
                checkDisable2: false,
                checkDisable3: false,
                checkDisable4: false,
                checkDisable5: false,
                checkDisable6: false,
                checkDisable7: false,
                checkDisable8: false,

                loading1: false,
                options1: {},
                xData1: [],

                colorList: [
                    "#00f4e0",
                    "#5b8ff9",
                    "#F1862A",
                    "#D3A61C",
                    "#295BAB",
                ],
                allNum: 0,

                yearValue2: new Date().getFullYear().toString(),
                monthValue2: [],
                holidayValue2: [],
                loading2: false,
                loading4: false,
                options2: {},
                xData2: [],
                allNum2: 0,

                date3: [],
                yearValue3: new Date().getFullYear().toString(),
                Jqvalue3: 0,
                monthValue3: [],
                holidayValue3: [],
                options3: {},
                xData3: [],
                enterList: [],
                leaveList: [],

                date4: [],
                yearValue4: new Date().getFullYear().toString(),
                Jqvalue4: 0,
                monthValue4: [],
                holidayValue4: [],
                options4: {},
                xData4: [],
                yData4: [],
                yData42: [],
                // 退票数据
                tsscData: [],
                tsscAllNum: 0,


                dbListAll: [],
                // 选择的对比数据
                dbList1: [],
                dbList2: [],
                dbList3: [],
                dbList4: [],
                dialogVisible: false,
                whichOne: 0,
                optionww: {},
                option1: {},
                option2: {},
                option3: {},
                option4: {},
                option5: {},
                option6: {},
                option7: {},
                optionbb: {},
                disabled1: false,
                disabled2: true,
                disabled3: true,
                disabled4: true,
                yesterdayFormatted: '',
                series1: [],
                series2: [],
                series3: [],
                series4: [],
                series5: [],
                series6: [],
                series7: [],

                xDataList2: [],
                xDataList3: [],
                xDataList4: [],
                xDataList5: [],
                xDataList6: [],

                plList1: [],
                plList2: [],
                plList3: [],
                plList4: [],
                plList5: [],
                plList6: [],
                editState: false,
                choosePart: '',
                chooseIndex: '',

                opId: 0,
                opTitle: '全部',
                opStart: '',
                opEnd: '',
                // 入园方式景区选择
                ruyuanJqId: 0,
                name1: ''
            };
        },
        created() {
            // 获取当天前面一天的日期
            this.getBeforeDate()
            this.getTjScenicSpotList()
        },
        methods: {
            getBeforeDate() {
                var today = new Date();
                var yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                this.yesterdayFormatted = yesterday.toISOString().slice(0, 10);
                this.opStart = yesterday.toISOString().slice(0, 10);
                this.opEnd = yesterday.toISOString().slice(0, 10);
                this.getDate1(this.yesterdayFormatted, this.yesterdayFormatted, '', this.ruyuanJqId)
                this.getDate2(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate3(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate4(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate5(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate6(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate7(this.yesterdayFormatted, this.yesterdayFormatted, 0, '全部')
            },
            // 获取景区数据
            getTjScenicSpotList() {
                tjScenicSpotList().then(res => {
                    this.Jqoptions = []
                    this.Jqoptions = res.data
                    const obj = {
                        id: 0,
                        name: '全部',
                    }
                    this.Jqoptions.unshift(obj)
                })
            },
            // 获取入园方式数据
            getDate1(val1, val2, val3, val4) {
                tjReportTouristType({start_date: val1, end_date: val2, scenic_spot_id: val4}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    obj.num = res.data.total_num
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }
                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = [res.data.ticket_num, res.data.card_num, res.data.free_num, res.data.total_num,]
                    // this.series1.push(obj2)
                    if (this.editState) {
                        // this.dbListAll.splice(this.chooseIndex, 1, obj)
                        this.plList1.splice(this.chooseIndex, 1, obj.title)
                        this.series1.splice(this.chooseIndex, 1, obj2)
                    } else {
                        // this.dbListAll.push(obj)
                        this.plList1.push(obj.title)
                        this.series1.push(obj2)
                    }
                }).then(() => {
                    this.getoption1()
                })
            },
            // 修改数据
            getDateB1(val1, val2, val3, val4, val5) {
                // val5 修改的下标
                tjReportTouristType({start_date: val1, end_date: val2, scenic_spot_id: val4}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    obj.num = res.data.total_num
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }
                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = [res.data.ticket_num, res.data.card_num, res.data.free_num, res.data.total_num,]
                    console.log('val5', val5)

                    this.plList1.splice(val5, 1, obj.title)
                    this.series1.splice(val5, 1, obj2)

                    // this.series1.push(obj2)
                    // if (this.editState) {
                    //     // this.dbListAll.splice(this.chooseIndex, 1, obj)
                    //     this.plList1.splice(this.chooseIndex, 1, obj.title)
                    //     this.series1.splice(this.chooseIndex, 1, obj2)
                    // } else {
                    //     // this.dbListAll.push(obj)
                    //     this.plList1.push(obj.title)
                    //     this.series1.push(obj2)
                    // }
                }).then(() => {
                    this.getoption1()
                })
            },


            // 获取入园人次数据
            getDate2(val1, val2, val3) {
                tjReportTourist({start_date: val1, end_date: val2}).then(res => {

                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].num
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }

                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList2 = []
                    res.data.forEach(item => {
                        this.xDataList2.push(item.scenic_spot_name)
                        obj2.data.push(item.num)
                    })

                    if (this.editState) {
                        this.plList2.splice(this.chooseIndex, 1, obj.title)
                        this.series2.splice(this.chooseIndex, 1, obj2)
                    } else {
                        this.plList2.push(obj.title)
                        this.series2.push(obj2)
                    }

                }).then(() => {
                    this.getoption2()
                })
            },
            // 获取收入对比数据
            getDate3(val1, val2, val3) {
                tjReportIncome({start_date: val1, end_date: val2}).then(res => {

                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].income
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }

                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList3 = []
                    res.data.forEach(item => {
                        this.xDataList3.push(item.scenic_spot_name)
                        obj2.data.push(item.income)
                    })
                    if (this.editState) {
                        this.plList3.splice(this.chooseIndex, 1, obj.title)
                        this.series3.splice(this.chooseIndex, 1, obj2)
                    } else {
                        this.plList3.push(obj.title)
                        this.series3.push(obj2)
                    }
                }).then(() => {
                    this.getoption3()
                })
            },
            // 获取人均消费数据
            getDate4(val1, val2, val3) {
                tjReportIncomePer({start_date: val1, end_date: val2}).then(res => {

                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].price
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }


                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList4 = []
                    res.data.forEach(item => {
                        this.xDataList4.push(item.scenic_spot_name)
                        obj2.data.push(item.price)
                    })
                    if (this.editState) {
                        this.dbListAll.splice(this.chooseIndex, 1, obj)
                        this.plList4.splice(this.chooseIndex, 1, obj.title)
                        this.series4.splice(this.chooseIndex, 1, obj2)
                    } else {
                        this.dbListAll.push(obj)
                        this.plList4.push(obj.title)
                        this.series4.push(obj2)
                    }
                }).then(() => {
                    this.getoption4()
                })
            },
            cannelDialog() {
                this.dialogVisible = false
            },
            sumbitDialog() {
                if (!this.date2 || this.date2.length <= 0) {
                    this.$message.error("请选择对比日期");
                    return false;
                }
                this.name1 = ''
                this.name1 = this.holidayValue2.length > 0 ? this.holidayValue2[0] : this.monthValue2.length > 0 ? this.monthValue2[0] : ''

                this.getDate1(this.date2[0], this.date2[1], this.name1, this.ruyuanJqId)
                this.getDate2(this.date2[0], this.date2[1], this.name1)
                this.getDate3(this.date2[0], this.date2[1], this.name1)
                this.getDate4(this.date2[0], this.date2[1], this.name1)

                setTimeout(()=>{
                    this.getDate5(this.dbListAll[0].date[0], this.dbListAll[0].date[1])
                    this.getDate6(this.dbListAll[0].date[0], this.dbListAll[0].date[1])
                    this.getDate7(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.opId, this.opTitle)
                },500)

                this.dialogVisible = false
            },
            handleEdit(val, item, index) {
                this.choosePart = val
                this.whichOne == 1
                this.chooseIndex = index
                this.editState = true
                this.dialogVisible = true

                this.date2 = item.date
                this.holidayValue2 = []
                this.monthValue2 = []
                if (item.holiday) {
                    this.checkDisable4 = false
                    this.checkDisable3 = true
                    this.holidayValue2 = item.holiday
                }
                if (item.month) {
                    this.checkDisable3 = false
                    this.checkDisable4 = true
                    this.monthValue2 = item.month
                }
            },
            // 移除对比
            moveDialog() {
                this.dbListAll.splice(this.chooseIndex, 1);

                this.plList1.splice(this.chooseIndex, 1);
                this.series1.splice(this.chooseIndex, 1);

                this.plList2.splice(this.chooseIndex, 1);
                this.series2.splice(this.chooseIndex, 1);

                this.plList3.splice(this.chooseIndex, 1);
                this.series3.splice(this.chooseIndex, 1);

                this.plList4.splice(this.chooseIndex, 1);
                this.series4.splice(this.chooseIndex, 1);
                this.dialogVisible = false
            },
            handleJump(val) {
                switch (val) {
                    case 0:
                        this.$router.push("/management");
                        break;
                    case 1:
                        this.$router.push("/nightTour");
                        break;
                }
            },
            showDb() {
                this.editState = false
                this.chooseIndex = ''
                if (this.dbListAll.length >= 5) {
                    this.$message.error('最多添加五组对比数据')
                    return false
                }
                this.getTjHolidayList(new Date().getFullYear());
                // this.whichOne = val
                this.holidayValue2 = []
                this.monthValue2 = []
                this.dialogVisible = true
                this.checkDisable3 = false
                this.checkDisable4 = false
            },
            // 获取节假日列表
            getTjHolidayList(year) {
                tjHolidayList({year: year}).then((res) => {
                    this.holidayList = res.data;
                });
            },
            // 选择景区
            changeJq(val) {
                const selectedOption = this.Jqoptions.find(option => option.id === val);
                this.opId = val
                this.opTitle = selectedOption.name
                this.getDate7(this.dbListAll[0].date[0], this.dbListAll[0].date[1], val, selectedOption.name)
            },
            changeJq2(val) {
                console.log('this.dbListAll', this.dbListAll)
                // this.plList1 = []
                // this.series1 = []
                // for (let i = 0; i < this.dbListAll.length; i++) {
                //     setTimeout(() => {
                //         this.getDateB1(this.dbListAll[i].date[0], this.dbListAll[i].date[1], this.dbListAll[i].title, val, i + 1)
                //     }, 2000)
                // }
                if(this.dbListAll.length==1){
                    this.getDateB1(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.dbListAll[0].title, val, 0)
                }
                if(this.dbListAll.length==2){
                    this.getDateB1(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.dbListAll[0].title, val, 0)
                    this.getDateB1(this.dbListAll[1].date[0], this.dbListAll[1].date[1], this.dbListAll[1].title, val, 1)
                }
                if(this.dbListAll.length==3){
                    this.getDateB1(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.dbListAll[0].title, val, 0)
                    this.getDateB1(this.dbListAll[1].date[0], this.dbListAll[1].date[1], this.dbListAll[1].title, val, 1)
                    this.getDateB1(this.dbListAll[2].date[0], this.dbListAll[2].date[1], this.dbListAll[2].title, val, 2)
                }
                if(this.dbListAll.length==4){
                    this.getDateB1(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.dbListAll[0].title, val, 0)
                    this.getDateB1(this.dbListAll[1].date[0], this.dbListAll[1].date[1], this.dbListAll[1].title, val, 1)
                    this.getDateB1(this.dbListAll[2].date[0], this.dbListAll[2].date[1], this.dbListAll[2].title, val, 2)
                    this.getDateB1(this.dbListAll[3].date[0], this.dbListAll[3].date[1], this.dbListAll[3].title, val, 3)
                }
                if(this.dbListAll.length==5){
                    this.getDateB1(this.dbListAll[0].date[0], this.dbListAll[0].date[1], this.dbListAll[0].title, val, 0)
                    this.getDateB1(this.dbListAll[1].date[0], this.dbListAll[1].date[1], this.dbListAll[1].title, val, 1)
                    this.getDateB1(this.dbListAll[2].date[0], this.dbListAll[2].date[1], this.dbListAll[2].title, val, 2)
                    this.getDateB1(this.dbListAll[3].date[0], this.dbListAll[3].date[1], this.dbListAll[3].title, val, 3)
                    this.getDateB1(this.dbListAll[4].date[0], this.dbListAll[4].date[1], this.dbListAll[4].title, val, 4)
                }
            },
            handleDate2() {
                if (this.date2 && this.date2.length > 0) {
                    this.monthValue2 = [];
                    this.holidayValue2 = [];
                }
                this.checkDisable3 = false;
                this.checkDisable4 = false;
            },
            chooseYaer2(val) {
                this.monthValue2 = [];
                this.holidayValue2 = [];
                this.checkDisable3 = false;
                this.checkDisable4 = false;
                this.getTjHolidayList(val);
                if (this.monthValue2.length > 0) {
                    var lasyDay = "";
                    lasyDay = this.getLastDay(val, this.monthValue2.toString());
                    this.date2 = [val + "-" + this.monthValue2.toString() + "-01", val + "-" + this.monthValue2.toString() + "-" + lasyDay];
                }
            },
            handleMonth2(val) {
                if (val.length > 0) {
                    this.date2 = []
                    this.checkDisable4 = true;
                    var lasyDay = "";
                    lasyDay = this.getLastDay(this.yearValue2, val.toString());
                    this.date2 = [this.yearValue2 + "-" + val.toString() + "-01", this.yearValue2 + "-" + val.toString() + "-" + lasyDay];
                } else {
                    this.checkDisable4 = false;
                }
            },
            handleHoliday2(val) {
                if (val.length > 0) {
                    this.checkDisable3 = true;
                    this.holidayList.forEach((item) => {
                        if (item.name == val.toString()) {
                            this.date2 = [item.start_date, item.end_date];
                        }
                    });
                } else {
                    this.checkDisable3 = false;
                }
            },


            //某年某月最后一天
            getLastDay(year, month) {
                var nyear = year;
                var nmonth = month++;
                if (month > 12) {
                    nmonth -= 12;
                    nyear++;
                }
                var n_date = new Date(nyear, nmonth, 1);
                return new Date(n_date.getTime() - 1000 * 60 * 60 * 24).getDate();
            },
            // 颜色
            show1(index1, width) {
                let color = "";
                color = this.colorList[index1];
                return `border-left: ${width} solid ${color};`;
            },


            getoption1() {
                this.option1 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList1,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['购票', '年卡', '免费', '总计']
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '人次',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series1
                };
            },
            getoption2() {
                this.option2 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList2,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList2
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '人次',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series2
                };
            },
            getoption3() {
                this.option3 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList3,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList3
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '收入',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series3
                };
            },
            getoption4() {
                this.option4 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList4,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList4
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '人均',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series4
                };
            },

            handleDate3(val) {
                if (val) {
                    this.getDate5(val[0], val[1])
                    this.getDate6(val[0], val[1])
                } else {
                    this.getDate5(this.yesterdayFormatted, this.yesterdayFormatted)
                    this.getDate6(this.yesterdayFormatted, this.yesterdayFormatted)
                }
            },

            // handleDate4(val) {
            //     if (val) {
            //         this.getDate7(val[0], val[1], this.opId, this.opTitle)
            //         this.opStart = val[0]
            //         this.opEnd = val[1]
            //     } else {
            //         this.getDate7(this.yesterdayFormatted, this.yesterdayFormatted, this.opId, this.opTitle)
            //     }
            // },

            // tjReportTeamType团散对比
            getDate5(val1, val2) {
                tjReportTeamType({start_date: val1, end_date: val2}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    obj.name = '团队'
                    obj.type = 'bar'
                    obj.barMaxWidth = 20
                    obj.data = []

                    obj2.name = '散客'
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []

                    this.xDataList5 = []
                    res.data.forEach(item => {
                        this.xDataList5.push(item.scenic_spot_name)
                        obj.data.push(item.team_percent)
                        obj2.data.push(item.single_percent)
                    })
                    this.series5 = [obj, obj2]
                }).then(() => {
                    this.getoption5()
                })
            },
            getoption5() {
                this.option5 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        data: ['团队', '散客'],
                        top: "1%",
                        left: "center",
                    },

                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList5
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '占比',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series5
                };
            },

            // 团队人数tj_report_team_num
            getDate6(val1, val2) {
                tjReportTeamNum({start_date: val1, end_date: val2}).then(res => {
                    this.series6 = []
                    res.data.forEach(item => {
                        const obj = {}
                        obj.value = item.num
                        obj.name = item.scenic_spot_name
                        obj.percent = item.percent
                        this.series6.push(obj)
                    })
                }).then(() => {
                    this.getoption6()
                })
            },

            getoption6() {
                this.option6 = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [{
                        name: '团队人数',
                        type: 'pie',
                        radius: '80%',
                        center: ['50%', '50%'],
                        clockwise: false,
                        data: this.series6,
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#999',
                                    fontSize: 14,
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        itemStyle: {
                            emphasis: {
                                borderWidth: 0,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }],
                    color: this.colorList,
                };
            },


            getDate7(val1, val2, val3, val4) {
                tjReportIncomeType({start_date: val1, end_date: val2, scenic_spot_id: val3}).then(res => {
                    this.series7 = []
                    const obj = {}
                    const obj2 = {}
                    obj.name = val4 + '项目'
                    obj.value = res.data.project_income
                    obj.percent = res.data.project_percent
                    obj2.name = val4 + '门票'
                    obj2.value = res.data.ticket_income
                    obj2.percent = res.data.ticket_percent
                    this.series7 = [obj, obj2]
                }).then(() => {
                    this.getoption7()
                })
            },
            getoption7() {
                this.option7 = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [{
                        name: '门票/项目占比',
                        type: 'pie',
                        radius: '80%',
                        center: ['50%', '50%'],
                        clockwise: false,
                        data: this.series7,
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#999',
                                    fontSize: 14,
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        itemStyle: {
                            emphasis: {
                                borderWidth: 0,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }],
                    color: this.colorList,
                };
            },


            // 示例
            getoptions() {
                this.optionww = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                    },
                    legend: {
                        data: ['团队', '散客'],
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['新虹桥', '中山公园', '虹桥', '镇宁路', '天山古北']
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '总价(万元)',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: [{
                        name: '包租费',
                        type: 'bar',
                        data: [20, 12, 31, 34, 31]
                    }, {
                        name: '装修费',
                        type: 'bar',
                        data: [10, 20, 5, 9, 3]
                    }, {
                        name: '保洁费',
                        type: 'bar',
                        data: [1, 1, 2, 3, 1]
                    }, {
                        name: '物业费',
                        type: 'bar',
                        data: [0.1, 2, 3, 1, 0.5]
                    }]
                };
            },
            getoptions22() {
                this.optionbb = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [{
                        name: '库存情况',
                        type: 'pie',
                        radius: '100%',
                        center: ['50%', '50%'],
                        clockwise: false,
                        data: [{
                            value: 45,
                            name: 'CARD'
                        }, {
                            value: 25,
                            name: 'SSD'
                        }, {
                            value: 15,
                            name: 'U盘'
                        }, {
                            value: 8,
                            name: '嵌入式'
                        }, {
                            value: 7,
                            name: 'FLASH'
                        }],
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#999',
                                    fontSize: 14,
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        itemStyle: {
                            emphasis: {
                                borderWidth: 0,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }],
                    color: this.colorList,
                };
            },

        }
        ,
        /**加载完组件时执行(加载完成之后执行)*/
        mounted() {
        }
        ,
    }
    ;
</script>

<style lang="scss" scoped>
    .management-box {
        .tip-box {
            cursor: pointer;
            margin-bottom: 23px;

            .every-tip {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleBg.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                color: #9bb0cc;
                line-height: 41px;
                margin-right: 32px;
            }

            .every-tip-actived {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleActived.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #ffbb00;
                line-height: 41px;
                margin-right: 32px;
            }

            .all-db-box {
                /*width: 680px;*/
                height: 44px;

                .db-box {
                    width: 96px;
                    background: url("../assets/daymanage/4-title-icon2@2x.png") no-repeat;
                    background-size: 100% 100%;
                    height: 32px;
                    /*opacity: 0.9;*/
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #022560;
                    line-height: 32px;
                    cursor: pointer;
                }

                .all-db {
                    /*width: 560px;*/
                    margin-right: 30px;

                    .every-db {
                        position: relative;
                        cursor: pointer;
                        height: 44px;
                        padding: 5px 10px 5px 20px;
                        opacity: 0.6;
                        background: #1e427c;
                        border: 1px solid #0372a6;

                        .pp1 {
                            opacity: 0.7;
                            font-size: 14px;
                            font-weight: normal;
                            text-align: left;
                            color: #ffffff;
                        }

                        .pp2 {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #1cffff;
                        }
                    }


                    .every-db:first-child::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #2775C8;
                    }

                    .every-db:nth-child(2)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #189E5D;
                    }

                    .every-db:nth-child(3)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #D27F05;
                    }

                    .every-db:nth-child(4)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #BC5A9D;
                    }

                    .every-db:nth-child(5)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #8C5DD0;
                    }


                }
            }
        }

        .all-box {
            .left-box {
                width: 598px;
                height: 810px;
                /*opacity: 0.9;*/
                border: 1px solid rgba(4, 70, 101, 0.81);
                background: url("../assets/daymanage/10-1title-jiedai-bg@2x.png") no-repeat;
                background-size: 100% 100%;
                padding: 24px;

                .img1 {
                    width: 548px;
                    height: 44px;
                    display: block;
                    margin-bottom: 24px;
                }

                .search-box {
                    img {
                        width: 306px;
                        height: 32px;
                    }

                    .db-box {
                        width: 96px;
                        background: url("../assets/daymanage/4-title-icon2@2x.png") no-repeat;
                        background-size: 100% 100%;
                        height: 32px;
                        /*opacity: 0.9;*/
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        color: #022560;
                        line-height: 32px;
                        cursor: pointer;
                    }
                }

                .all-db {
                    margin: 18px 0 0;

                    .every-db {
                        position: relative;
                        cursor: pointer;
                        height: 70px;
                        padding: 5px 0 5px 20px;
                        opacity: 0.6;
                        background: #1e427c;
                        border: 1px solid #0372a6;

                        .pp1 {
                            opacity: 0.7;
                            font-size: 14px;
                            font-weight: normal;
                            text-align: left;
                            color: #ffffff;
                        }

                        .pp2 {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #1cffff;
                        }
                    }

                    .every-db:first-child::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #2775C8;
                    }

                    .every-db:nth-child(2)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #189E5D;
                    }

                    .every-db:nth-child(3)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #D27F05;
                    }

                    .every-db:nth-child(4)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #BC5A9D;
                    }

                    .every-db:nth-child(5)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #8C5DD0;
                    }


                }
            }

            .right-box {
                width: 1219px;
                height: 810px;
                /*opacity: 0.9;*/
                border: 1px solid rgba(4, 70, 101, 0.81);
                background: url("../assets/daymanage/10-1title-jingying-bg@2x.png") no-repeat;
                background-size: 100% 100%;
                position: relative;

                .info1 {
                    width: 598px;
                    height: 100%;
                    padding: 24px;

                    .img1 {
                        width: 288px;
                        height: 44px;
                        display: block;
                        margin-bottom: 24px;
                    }
                    .renjun-img {
                        position: relative;

                        .wenHao {
                            position: absolute;
                            top: 3px;
                            left: 130px;
                            width: 24px;
                            height: 24px;

                        }
                    }


                    .search-box {
                        img {
                            width: 306px;
                            height: 32px;
                        }


                        .db-box {
                            width: 96px;
                            background: url("../assets/daymanage/4-title-icon2@2x.png") no-repeat;
                            background-size: 100% 100%;
                            height: 32px;
                            /*opacity: 0.9;*/
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            color: #022560;
                            line-height: 32px;
                            cursor: pointer;
                        }
                    }

                    .all-db {
                        margin: 18px 0 0;

                        .every-db {
                            position: relative;
                            cursor: pointer;
                            height: 70px;
                            padding: 5px 0 5px 20px;
                            opacity: 0.6;
                            background: #1e427c;
                            border: 1px solid #0372a6;

                            .pp1 {
                                opacity: 0.7;
                                font-size: 14px;
                                font-weight: normal;
                                text-align: left;
                                color: #ffffff;
                            }

                            .pp2 {
                                font-size: 16px;
                                font-weight: 400;
                                text-align: left;
                                color: #1cffff;
                            }
                        }

                        .every-db:first-child::before {
                            position: absolute;
                            content: '';
                            width: 7px;
                            height: 7px;
                            top: 9px;
                            left: 6px;
                            background: #2775C8;
                        }

                        .every-db:nth-child(2)::before {
                            position: absolute;
                            content: '';
                            width: 7px;
                            height: 7px;
                            top: 9px;
                            left: 6px;
                            background: #189E5D;
                        }

                        .every-db:nth-child(3)::before {
                            position: absolute;
                            content: '';
                            width: 7px;
                            height: 7px;
                            top: 9px;
                            left: 6px;
                            background: #D27F05;
                        }

                        .every-db:nth-child(4)::before {
                            position: absolute;
                            content: '';
                            width: 7px;
                            height: 7px;
                            top: 9px;
                            left: 6px;
                            background: #BC5A9D;
                        }

                        .every-db:nth-child(5)::before {
                            position: absolute;
                            content: '';
                            width: 7px;
                            height: 7px;
                            top: 9px;
                            left: 6px;
                            background: #8C5DD0;
                        }
                    }
                }

                .info2 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 598px;
                    height: 524px;
                    /*opacity: 0.9;*/
                    border: 1px solid rgba(4, 70, 101, 0.81);
                    background: url("../assets/daymanage/10-1title-tuandui-bg@2x.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 24px;

                    .img3 {
                        width: 330px;
                        height: 44px;
                        display: block;
                        margin-bottom: 16px;
                    }

                    .img4 {
                        width: 306px;
                        height: 32px;
                        display: block;
                        margin-bottom: 8px;
                    }

                    .img5 {
                        width: 306px;
                        height: 32px;
                        display: block;
                        margin: 44px 0 12px;
                    }

                    .right-data-show {
                        width: 388px;
                        height: 140px;

                        .info1-box {
                            width: 100%;
                            height: 100%;
                        }

                        .echart_r {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;
                            margin-left: 30px;

                            .echart_r_box {
                                width: 33%;
                                margin-bottom: 22px;

                                .echart_r_box1,
                                .echart_r_box2 {
                                    height: 28px;
                                    line-height: 28px;
                                    padding-left: 18px;
                                    font-size: 16px;
                                    color: #9bb0cc;
                                }

                                .echart_r_box2 {
                                    margin-left: 2px;
                                    font-size: 16px;
                                    font-weight: 700;
                                    color: #1CFFFF;
                                    text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);

                                    span {
                                        color: #9bb0cc;
                                    }
                                }
                            }
                        }
                    }
                }

                .info3 {
                    position: absolute;
                    right: 0;
                    top: 548px;
                    width: 598px;
                    height: 258px;
                    padding: 24px;

                    img {
                        width: 206px;
                        height: 32px;
                        display: block;
                    }

                    .right-data-show {
                        width: 388px;
                        height: 140px;

                        .info1-box {
                            padding-top: 35px;
                            width: 100%;
                            height: 100%;
                        }

                        .echart_r {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;
                            margin-left: 30px;

                            .echart_r_box {
                                width: 50%;
                                margin-bottom: 22px;

                                .echart_r_box1,
                                .echart_r_box2 {
                                    height: 28px;
                                    line-height: 28px;
                                    padding-left: 18px;
                                    font-size: 16px;
                                    color: #9bb0cc;
                                }

                                .echart_r_box2 {
                                    margin-left: 2px;
                                    font-size: 16px;
                                    font-weight: 700;
                                    color: #1CFFFF;
                                    text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);

                                    span {
                                        color: #9bb0cc;
                                    }
                                }
                            }
                        }
                    }

                }

            }
        }

    }

    .kjxz {
        text-align: center;
        font-size: 16px;
        margin: 10px 0;
        color: #999999;
    }


    .flex {
        display: flex;
    }

    .mt23 {
        margin-top: 17px;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .mb45 {
        margin-bottom: 45px;
    }

    .mb10 {
        margin-bottom: 15px;
    }
</style>
<style lang="scss">
    .right-select .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .right-select .el-input__icon {
        line-height: 28px !important;
    }

    .right-select2-1 {
        width: 195px;
    }

    .right-select2 .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .right-select2 .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        /*background: #042c60 !important;*/
        border: none !important;
        /*color: #37ecff !important;*/
    }

    .info2 .all-date-box .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .info2 .all-date-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .info2 .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }


    .info3 .all-date-box .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .info3 .all-date-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .info3 .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }


    .all-date-box .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .all-date-box .el-range-separator {
        color: #37ecff !important;
    }

    .chooseYearBox .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
    }

    .chooseYearBox .el-input__icon {
        line-height: 28px !important;
    }

    .chooseYearBox .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .is-selected {
        color: #1989fa;
    }

    .calendar-info {
        font-size: 12px;
    }

    .el-checkbox {
        margin: 0 5px 5px 0;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
    }

    .management-box .el-dialog__body {
        padding: 10px 20px !important;
    }


</style>
